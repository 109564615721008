<template>
  <div class="activityDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="专家名称">
              <el-input v-model="form.docterName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专家职称">
              <el-input v-model="form.docterTitle"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="头像" prop="name">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :show-file-list="false"
                action="/api/wx/user/upload/expert"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="form.headImg" :src="form.headImg" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签名" prop="name">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :show-file-list="false"
                action="/api/wx/user/upload/expert"
                :on-success="handleAvatarSuccess2"
              >
                <img v-if="form.signImg" :src="form.signImg" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="详情图片" prop="name">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :show-file-list="false"
                action="/api/wx/user/upload/expert"
                :on-success="handleAvatarSuccess3"
              >
                <img
                  v-if="form.detailImg"
                  :src="form.detailImg"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "新增专家",
      dialogImageUrl: "",
      dialogVisible: false,
      headers: {
        authorization: localStorage.getItem("token"),
      },
      memberId: "",
      form: {
        docterName: "",
        headImg: "",
        detailImg: "",
        signImg: "",
        docterTitle: "",
      },
    };
  },
  components: {},
  methods: {
    handleAvatarSuccess(res, file) {
      this.form.headImg = res.data.downloadUrl;
    },
    handleAvatarSuccess2(res, file) {
      this.form.signImg = res.data.downloadUrl;
    },
    handleAvatarSuccess3(res, file) {
      this.form.detailImg = res.data.downloadUrl;
    },
    async submitData() {
      let params = { ...this.form };
      let url = "";
      if (params.id) {
        //修改
        url = "api/admin/doctor/add";
      } else {
        url = "api/admin/doctor/add";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增专家";
      this.form = {};
    },
    getInfo(item) {
      this.dialogTitle = "编辑专家";
      this.form = JSON.parse(JSON.stringify(item));
      this.visible = true;
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
